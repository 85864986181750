import type { MaybeRefOrGetter } from 'vue'
import { join } from 'pathe'

export interface DirectusThumbnailOptions {
  width?: number
  height?: number
  quality?: number
  fit?: 'cover' | 'contain' | 'inside' | 'outside'
  format?: 'jpg' | 'png' | 'webp' | 'tiff'
  withoutEnlargement?: boolean
  key?: string
}

export function useDirectusAsset(
  _id: MaybeRefOrGetter<string | undefined | null>,
  _options?: MaybeRefOrGetter<DirectusThumbnailOptions>,
) {
  const config = useRuntimeConfig().public.directus

  return computed(() => {
    const id = toValue(_id)
    const options = toValue(_options)

    if (!id) {
      return ''
    }

    const url = new URL(join(config.rest.baseUrl, 'assets', id))
    if (options) {
      if (options.width) {
        url.searchParams.append('width', options.width.toFixed(0))
      }
      if (options.height) {
        url.searchParams.append('height', options.height.toFixed(0))
      }
      if (options.quality) {
        url.searchParams.append('quality', options.quality.toFixed(0))
      }
      if (options.withoutEnlargement) {
        url.searchParams.append('withoutEnlargement', 'true')
      }
      if (options.fit) {
        url.searchParams.append('fit', options.fit)
      }
      if (options.format) {
        url.searchParams.append('format', options.format)
      }
      if (options.key) {
        url.searchParams.append('key', options.key)
      }
    }
    return url.href
  })
}
